@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;400;700;900&display=swap');
:root{
  --dark-suit: #2E3440;
  --golden-oriale: #E4B52B;
  --ice-coffee: #BF8339;
  --tan-suit: #A66B37;
  --white-milk: #F5F5F5;
  --influencer-blue: #3371F2;
  --legal-gray: #666666;
  --office-blue: #90ACF7;
}

*{
  /* border: 1px solid red; */
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  background-color: var(--milk);
  font-size: 16px;
  color: var(--dark-suit);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1{
  color: var(--white-milk);
  font-size: clamp(3.0rem, 2.4vw, 3.75rem);
}
h2{
  font-size: clamp(2.25rem, 2.4vw, 2.75rem);

}
h3{
  font-size: clamp(1.25rem, 2.4vw, 1.75rem);
  
}
h4{
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--dark-suit);
  opacity: 80%;
}

p{
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  font-size: clamp(1rem, 2.4vw, 1.25rem);
  line-height: clamp(1.25rem, 2.4vw, 2rem);
  /* line-height: 1.75rem; */
}

section{
  max-width: 100vw;
  margin: clamp(3rem, 5rem, 6rem) 0;
  padding: clamp(3rem, 5rem, 6rem) 0;
  /* margin: 5rem 0;
  padding: 5rem 0; */
}

hr{
  width: 80%;
  color: var(--ice-coffee)
}


@media (min-width: 640px){
  .underline-heading{
    text-underline-offset: 20px;
  }

}

/* ============ */
/* Hero Section */
.hero-section{
  height: 100%;
  width: 100%;
  /* background-color: var(--dark-suit); */
  background-image: url('./assets/images/hero_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;
}

.hero-content-container{
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0 auto;
  width: 80%;
  max-width: 1536px;
}

@media (min-width: 1024px) {
  .hero-content-container{
    flex-wrap: nowrap;
  }
  .hero-section{
    background-size: cover;
  }
}

.hero-content{
  flex-basis: 100%;
  margin-right: 2rem;

}
.hero-content h4{
  color: white;
  font-weight: 900;
}
.hero-content p{
  font-family: 'Roboto';
  color: var(--white-milk);
}

.hero-content h1{
  /* font-family: 'Roboto'; */
  font-weight: 700;
}

.hero-img-container{
  flex-basis: 100%;
  margin-left: 2rem;
}

.hero-img-container img{
  width: 95%;
  border-radius: 1rem;
}
.hero-button-container{
  display: flex;
  justify-items: flex-start;
}

.hero-button-container a{
  text-decoration: none;
}
.hero-button{
  background-color: var(--golden-oriale);
  border-radius: 1rem;
  margin-right: 1rem;
  font-weight: 400;
}

.hero-button p{
  color: var(--dark-suit);
  font-size: clamp(0.75, 2.5vw, 1.25rem);
  padding: 0 1.25rem;
  height: 3rem;
  line-height: 3rem;
}

.hero-button:hover{
  animation: hero-btn-hover 0.25s ease-in 1 forwards;
}

@keyframes hero-btn-hover {
  100%{
    transform: scale(1.1);
    font-weight: 700;
    box-shadow: 5px 5px 10px #000;
  }
}



/* ============= */
/* Intro Section */
.intro-section{
  width: 100%;
  max-width: 1536px;
  margin: 0 auto;
}

.intro-container{
  /* width:100%; */
  margin: 0 auto;
  display: flex;
  /* flex-grow: 1; */
  flex-shrink: 1;
  flex-basis: 0;
  flex-wrap: wrap;
  justify-content: center;
  
}

@media screen and (min-width: 1024px){
  .intro-container{
    /* max-width: 1340px; */
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.intro-profile-img{
  object-fit: contain;
  width: 100%;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.intro-text{
  padding: 2rem;
  width: 100%;
  flex-basis: 100%;
}

@media screen and (min-width: 768px) {
  .intro-text{
    width: 40%;
    margin-left: 5rem;
  }
  .intro-profile-img{
    width: 60%;
  }
}

.intro-text h4{
  color: #000;
  font-weight: 900;
}

.intro-blurb{
  font-family: 'Raleway';
  color: var(--legal-gray);
  line-height: 1.75rem;
  flex-basis: 100%;
}

.intro-accreditation-pills{
  display: flex;

}

.accreditation-pill{
  color: white;
  margin: 0 1rem 0 0;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  vertical-align: center;
  font-weight: 600;
  font-size: 1rem;
}

/* =================== */
/* Get Unstuck Classes */
.get-unstuck-container{
  width: 80%;
  max-width: 1536px;
  margin: 0 auto;
}

.unstuck-content-container{
  display: flex;
  flex-wrap: wrap;
}

.unstuck-menu h3{
  margin: 2rem 1rem;
  cursor: pointer;
  flex-basis: 30%;
  opacity: 0.6;
  text-decoration: underline white;
  text-underline-offset: 0.25rem;
  transition: all 0.25s linear 0s;
}
.unstuck-menu h3:hover{
  text-decoration: underline var(--ice-coffee);
  text-underline-offset: 0.25rem;
  color: var(--dark-suit);
  opacity: 1;
}

.unstuck-content{
  flex-basis: 100%;
  /* max-width: 1000px; */
}

@media screen and (min-width: 640px) {
  .unstuck-content-container{
    flex-wrap: nowrap;
  }
  .unstuck-content{
    margin: 0 4rem;
    flex-basis: 70%;
  }

}

.unstuck-content p{
  font-family: 'Raleway';
  /* font-size: 1.25rem; */
  line-height: 2rem;
}

.unstuck-content img{
  width: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

/* =================== */
/* Testimonial Classes */
.testimonial-container{
  position: relative;
  margin: 4rem auto;
  padding: 4rem;
}

@media screen and (min-width:768px){
  .testimonial-container{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1rem;
    max-width: 80%;
    margin: 4rem auto;
  }
}

@media screen and (min-width: 1536px) {
  .testimonial-container{
    max-width: 60%;
  }
}

.testimonial-container  blockquote{
  /* font-size: 1.5rem; */
  font-size: clamp(1.1rem, 2.4vw, 1.5rem);
  margin: 2rem 0;

}
.testimonial-container h4{
  color: var(--tan-suit);
  font-size: 1.5rem;
  font-style: normal;
}

.testimonial-container cite p{
  /* font-size: 1rem; */
  font-style: normal;
}

.testimonial-image-container{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-image{
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.quote-mark-container{
  position: absolute;
}

.top-quote{
  position: absolute;
  top: -20%;
  left: 5%;
}

.bottom-quote{
  position: absolute;
  bottom: 0;
  right: 0;
}

/* ================= */
/* Expertise classes */
.expertise-section{
  margin: 0 2rem 0 2rem;
}

.expertise-side-shot{
  display: none;
}

@media screen and (min-width: 768px){
  .expertise-section{
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    margin: 0 2rem 0 0;
  }
  .expertise-side-shot{
    display:block;
  }
}

.expertise-text-content{
  margin: 0 auto;
}

@media screen and (min-width: 360px){
  /* Keep title in one line on small screens */
  .expertise-heading{
    font-size: 2.75rem;
  }
}
.expertise-card-container{
  width: 95%;
  border-radius: 1rem;
  padding: 1rem;
  transition: color 1s;
  border: 2px solid transparent;
  margin: 2rem auto;
}

.expertise-card-container:hover{
  border: 2px solid var(--golden-oriale);
  transition: border 250ms linear;
}

.expertise-text{
  display: flex;
  justify-content: space-between;
}

.expertise-text h4 {
  font-weight: 600;
  color: black;
  margin: 0 0 2rem 0;
}

.expertise-text p {
  margin: 0 0 2rem 0;
}

.expertise-fill{
  position: relative;
}

.expertise-background, .expertise-foreground{
  position: absolute;
  top: -1rem;
  left:0;
  height: 15px;
  width: 100%;
  border-radius: 4rem;
  background-color: var(--white-milk);
}

.expertise-foreground{
  background-color: var(--golden-oriale);
  width: 80%;
}

.work-exp-container{
  height: fit-content;
  width: 100vw;
  margin-left: 0;
}

.work-exp-card{
  width: 95%;
  /* background-color: var(--white-milk); */
  box-shadow: 0 0 20px lightgrey;
  margin: 6rem 0.5rem;
  transition: 0.5s;
  border-radius: 0.5rem;
}

.swiper-slide:hover{
  z-index: 10; 
}

.work-exp-card:hover{
  z-index: 1;
  cursor: pointer;
  z-index: 1;
  transform: scale(1.05) translateY(0.5rem)
}

@media (min-width: 768px){
  .work-exp-card:hover{
    z-index: 1;
    transform: scale(1.1) translateY(1rem)
  }
}

.work-exp-card-img {
  max-width: 100%;
  height: 15rem;
  background-size:cover;
  background-position: center;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.work-exp-card .text-content{
  min-height: 20vh;
}

.work-exp-card .text-content .subheading{
  padding-top: 1rem;
  text-align: center;
  color: gray;
}
.work-exp-card .text-content .title{
  padding: 0 0.5rem;
  text-align: center;
  font-size: 1.5rem;

}
.work-exp-card .text-content .blurb{
  width: fit-content;
  padding: 1rem 1rem 3rem 1rem;
  text-align: center;
  color: var(--legal-gray);
  font-family: 'Raleway';

}

/* ========================== */
/* Thought Leadership / Blog  */
.blog-heading{
  padding: 2rem 0;
} 

.blog-item{
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin: 2rem 0; 
  overflow: hidden;
}

.blog-img{
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  object-fit:contain;
  margin: 0 2rem 1rem 0;
  flex-basis: 50%;
}

.blog-content{
  position: relative;
  flex-basis: 100%;
  margin: 0 0 2rem 0;
}

.blog-body{
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
  line-height: 1.75rem;
  margin-bottom: 4rem;
}

@media screen and (min-width: 1024px) {
  .blog-img{
    width: 50%;
    flex-basis: 50%;
  }
  .blog-item{
    flex-wrap: nowrap;
  }
  .blog-content{
    flex-basis: 50%;
  }
}

.blog-subheading{
  font-weight: bold;
  font-size: medium;
  text-transform: uppercase;
  margin-right: 1rem;
  padding-right: 1rem;
}

.blog-date{
  font-weight: normal;
  color: gray;
  margin: 0 0 0 1rem;
}

.blog-social-grouping{
  width: 100%;
  position: absolute;
  left:0;
  bottom:0;
}
.blog-social-media{
  /* display: inline-block; */
  /* float: right; */
  color: var(--influencer-blue);
  margin: 0 0.5rem;
  transition: all 0.25s;
}

.blog-social-media:hover{
  transform: scale(1.25);
}

.social-media-icons{
  display: flex;
  justify-content: flex-end;
}

.blog-read-more{
  margin-right: auto;
  margin-left: 0;
  text-decoration: underline solid 4px var(--influencer-blue);
  text-underline-offset: 5px;
  color: var(--influencer-blue);
  font-weight: bold;
}

/* ================ */
/* About Me classes */


.about-container{
  position: relative;
  width: 90%;
  max-width: 1800px;
  margin: 0 auto;
}

.about-heading, .about-content-containe{
  line-height: 4rem;
}

.about-content-container{
  padding: 2rem;
  border-bottom-right-radius: 2rem;
}

.about-blurb{
  color: var(--legal-gray)
}

@media screen and (min-width: 1024px){
  .about-content-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    background-color: var(--white-milk);
  }
  .about-heading > span + span{
    display: block;
  }
}

.about-img{
  width: 100%;
  object-fit: contain;
}

/* =============== */
/* Contact classes */
.contact-section{
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 98%;
  max-width: 1800px;
  border-radius: 1rem;
  background-color: var(--dark-suit);
  color: var(--white-milk)
}

.contact-content{
  width: 100%;
  margin: 2rem 3rem;
}

.contact-content > h2{
  margin: 0.25rem 0;
}

.contact-content div > p{
  /* display: inline-block; */
  font-weight: bold;
  padding: 0.25rem 0;
  line-height: 2rem;
  /* vertical-align: text-bottom; */
}

.contact-content div > p > svg{
  vertical-align: middle;
  margin-right: 0.5rem;
}

.contact-form{
  /* flex-basis: 50%; */
  flex-grow: 1;
  flex-shrink: 1;
  margin: 2rem 1rem;
  background-color: var(--golden-oriale);
  border-radius: 1rem;
}

form > div{
  margin: 2rem;
}

form > div > label{
  display: block;
  color: var(--dark-suit);
  font-weight: bold;
  margin: 1rem 0;
}

form {
  margin: 0 auto;
}

form > div {
  max-width: 100%;
}

form > div > input, textarea{
  width: 95%;
  border-radius: 0.5rem;
  border: solid lightgrey 1px;
  margin: 0 auto;
  padding: 0 1rem;
  font-size: 1rem;
}
form > div > input{
  height: 3rem;
}

input::placeholder, textarea::placeholder{
  color: #999999;
  font-family: 'Raleway';
}
textarea{
  padding: 1rem;
}

.button-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.button-container > button{
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.025rem;
  padding: 1rem 3rem;
  background-color: var(--dark-suit);
  color: var(--white-milk);
  border-radius: 0.5rem;
}
button:hover{
  cursor:pointer;
}

@media screen and (min-width: 1200px) {
  .contact-section{
    flex-wrap: no-wrap;
  }
  .contact-content{
    width: 25%;
    margin: 2rem 6rem;
  }
  .contact-form{
    margin: 2rem 6rem;
  }
}

.spinner{
  opacity: 0;
  animation: fade-in 0.5s 1 forwards, spin 2s linear infinite, fade-out 0.5s linear 2.5s 1 forwards;
}

.form-icon{
  margin-right: 1rem;
}

.form-check{
  opacity: 0;
  animation: fade-in 0.5s 1 forwards, fade-out 0.5s linear 2s 1 forwards;
}

@keyframes fade-in{
  100%{
    opacity: 1
  }
}

@keyframes fade-out{
  100%{
    opacity: 0
  }
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

/* ============ */
/* Nav classes */
nav{
  /* background-color: var(--dark-suit); */
  display: flex;
  position: absolute;
}

.nav-container{
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  align-items: center;
}

.force-newline-wrap{
  flex-basis: 50%;
}

.logo{
  font-size: 1.5rem;
}

.nav-container li {
  margin-right: 2rem;
}

.nav-list{
  background-color: var(--dark-suit);
}

.nav-list-flex{
  display: flex;
  flex-wrap: no-wrap;
  
}

.nav-list-block li{
  margin: 2rem 0;
}

.list-item{
  color: var(--white-milk);
  font-weight: 600;
  font-family: 'Raleway';
  text-decoration: none;
}

.nav-hamburger{
  cursor: pointer;
}

.list-animation:hover{
  animation: transition-underline 0.25s linear 0s 1 forwards; 
}

@keyframes transition-underline{
  0%{
    text-decoration: none;
    text-decoration: underline var(--dark-suit) 4px;
    text-underline-offset: 20px;
  }
  100%{
    font-weight: 900;
    text-underline-offset: 5px;
    text-decoration: underline var(--white-milk) 4px;
  }
}

@media (min-width:640px) {
  
}


/* ============== */
/* Footer classes */
.footer-container{
  width: 100%;
  margin: 4rem auto;
  text-align: center;
}
.footer-container h3{
  font-size: 1.3rem;
  color: var(--tan-suit);
  font-weight: 900;
}

.footer-link{
  margin: 0 1.5rem;
  text-decoration: none;
  color: var(--legal-gray);
  font-weight: 500;
}

.internal-links{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
}

.footer-link:hover{
  color: var(--influencer-blue);
  text-decoration: underline;
  text-underline-offset: 0.5rem;
}

.social-links{
  margin: 2.5rem 0;
  display: flex;
  justify-content: center;
}

.social-links a{
  text-decoration: none;
}

/* =============== */
/* Utility classes */
.util-gray-background{
  background: var(--white-milk);
}

.util-width-80{
  width: 80%;
  margin: 0 auto;
}

.util-inline{
  display: inline;
}

.util-hidden{
  display:none;
}

.util-block{
  display: block;
}

.underline-heading{
  text-decoration: underline;
  text-decoration-color: var(--ice-coffee);
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
  /* font-size: 2rem; */
}

.util-fadeout{
  animation: fade-out 0.5s 1 forwards;
}
.util-fadein{
  animation: fade-in 0.5s 1 forwards;
}
